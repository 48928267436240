import React, { useState } from 'react';
import { useLocalStorage } from 'react-use-storage';
import { post } from '../../utils/httpRequests';
import { useHistory, withRouter } from 'react-router-dom';
import config from '../../config.json';

import './loginModal.css';

const sha512 = require('js-sha512').sha512;

const LoginModalComponent = (props) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [loggedIntoApp, setIsLoggedIntoApp] = useState(localStorage.getItem("loginObject") && JSON.parse(localStorage.getItem("loginObject")).token !== undefined ? true : false);
    const [alertUnSuccess, setAlertUnSuccess] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginObject, setLoginObject, removeValue] = useLocalStorage("loginObject", localStorage.getItem("loginObject") && JSON.parse(localStorage.getItem("loginObject")).token !== undefined ? localStorage.getItem("loginObject") : {});

    const closeModal = () => {
        document.body.style.height = 'auto';
        document.body.style.overflow = 'unset';
        setShowModal(false);
    };

    const doLogOut = () => {
        setLoginObject({});
        setIsLoggedIntoApp(false);
        setShowModal(false);
        history.push((config.root ? '' : config.home) + '/')
        window.location.reload();
    };

    const openModal = () => {
        document.body.style.height = '100vh';
        document.body.style.overflow = 'hidden';
        setShowModal(true);
    };

    const handleUsername = (event) => {
        setUsername(event.target.value);
    };

    const handlePassword = (event) => {
        setPassword(event.target.value);
    };


    const handleAlertClosed = (event) => {
        setAlertUnSuccess(false);
    }

    const sendLoginRequest = (event) => {
        event.preventDefault();
        setIsLoading(true)
        var hashPassword = sha512(password);
        const data = {
            'username': username,
            'password': hashPassword
        }
        post('check', 'login', data, loginObject).then(response => {
            setIsLoading(false);
            if (response.data.errorCode) {
                setAlertUnSuccess(true);
            }
            else {
                setAlertUnSuccess(false);
                setShowModal(false);
                setIsLoggedIntoApp(true);
                setLoginObject(response.data);
                window.location.reload();
                //props.history.go(-(props.history.length - 1))
                //props.history.push((config.root ? '' : config.home) + '/');
                //localStorage.setItem('loginObject', response.data);
            }
        }).catch((e) => {
            setAlertUnSuccess(true);
        })
        return false;
    };

    return (
        <div className={'ModalLogin ' + props.type}>
            <span onClick={openModal}>{loggedIntoApp ? 'Logout' : props.children}</span>
            {
                showModal ?
                    loggedIntoApp ?
                        <div id="modale-login" className="modal">
                            <div onSubmit={sendLoginRequest} className="modal-content animate shadow">
                                <div className="imgcontainer">
                                    <span onClick={closeModal} className="close" title="Close Modal">&times;</span>
                                </div>
                                <div className="container">
                                    <p>Are you sure you want to log out? </p>
                                    <div className="container-button">
                                        <button className="w3-button w3-section button" type="button" disabled={loading} onClick={doLogOut}>
                                            <div className="anim"></div>
                                            <span>Yes</span>
                                        </button>
                                        <button className="w3-button w3-section button" type="button" disabled={loading} onClick={closeModal}>
                                            <div className="anim"></div>
                                            <span>Cancel</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div id="modale-login" className="modal">
                            <form onSubmit={sendLoginRequest} className="modal-content animate shadow">
                                <div className="imgcontainer">
                                    <span onClick={closeModal} className="close" title="Close Modal">&times;</span>
                                </div>
                                <div className="container">
                                    <p>Enter your credentials to take advantage of all <span className="ks-logo"><span>k</span>nowing <span>s</span>cience</span> services</p>
                                    {
                                        alertUnSuccess ?
                                            <div className="w3-panel w3-display-container alert-login">
                                                <span onClick={handleAlertClosed} className="alert-close">×</span>
                                                <p>Contact <a href="mailto:info@knowingscience.com">info@knowingscience.com</a> for help</p>
                                            </div>
                                            : null
                                    }
                                    <div className="input-container">
                                        <i className="fas fa-user" />
                                        <input className="w3-input w3-padding-16" name="uname" type="text" placeholder="Username" onChange={handleUsername} required />
                                    </div>
                                    <div className="input-container">
                                        <i className="fas fa-key" />
                                        <input className="w3-input w3-padding-16" name="psw" type="password" placeholder="Password" onChange={handlePassword} required />
                                    </div>
                                    {
                                        <button className="w3-button w3-section button" type="submit" disabled={loading}>
                                            {
                                                loading ?
                                                    <div className="load-wrapp">
                                                        <div className="load-2">
                                                            <div className="line"></div>
                                                            <div className="line"></div>
                                                            <div className="line"></div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="anim"></div>
                                                        {loggedIntoApp ? <span>Logout</span> : <span>Login</span>}
                                                    </>
                                            }
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    : null
            }
        </div>
    );

};

export default withRouter(LoginModalComponent);
