import React from 'react';
import VideoComponent from '../video-component/video-component';
import './attachmentModal.css';
import SlideshowComponent from '../slideshow/slideshow';

const AttachmentModalComponent = (props) => {

    return (
        props.showModal ?
            <div className={'ModalAttachment ' + props.type}>
                {
                    <div id="modale-attachment" className="modal">

                        <div className="modal-content animate shadow">
                            <div className="imgcontainer">
                                <span onClick={props.onClose} className="close" title="Close Modal">&times;</span>
                            </div>
                            {
                                <div className="container">
                                    {
                                        handleAttachment(props.content)
                                    }
                                </div>

                            }

                        </div>
                    </div>

                }
            </div>
            :
            null
    );

    function handleAttachment(attachment) {
        if (attachment.type === 'VIDEO') {
            return (
                <VideoComponent videoUrl={attachment.content}></VideoComponent>
            );
        } else if (attachment.type === 'IMGS') {
            return (
                <div>
                    <SlideshowComponent
                        slidesPerView={1}
                        spaceBetween={5}
                        loop={true}
                        autoplay={true}
                        content={attachment.galleryImages.map(i => {
                            return <img src={i} />
                        })}
                    />
                </div>
            );
        } else if (attachment.type === 'HTML') {
            return (
                <iframe src={ attachment.codePage } title="code"></iframe>
            );
        }
    }


}

export default AttachmentModalComponent;
