import "./quotes.css";
import openQuotes from "../../assets/images/quotes.png";

import React, { useEffect, useState } from "react";

const QuotesComponent = (props) => {
	
	const [current, setCurrent] = useState(0);
	const [interval, setChangeInterval] = useState(null);

	function nextQuote() {
		setCurrent(current => {
			if (current === props.quotes.length - 1) {
				return 0;
			}
			return current + 1;
		});
	};

	function prevQuote() {
		setCurrent(current => {
			if (current === 0) {
				return props.quotes.length - 1;
			}
			return current - 1;
		});
	};

	useEffect(() => {
		if (!interval) {
			const i = setInterval(function() {
				nextQuote();
			}, 8000);
			setChangeInterval(i);
		}
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, []);

	return (
		<div className="slideshow-container component-full">
			<div className="w3-row">
				<img className="open-quotes" src={openQuotes}></img>
			</div>
			<a onClick={prevQuote} className="prev" id="prev">&#10094;</a>
			<a onClick={nextQuote} className="next" id="next">&#10095;</a>
			<div className="quoteRow">
				<div className='quoteSlides'>
					<p className="quote" dangerouslySetInnerHTML={{ __html: props.quotes[current].body }}></p>
					<div className="infoRow">
						<span className="author">{props.quotes[current].author + ", " + props.quotes[current].role}</span>
						<span className="from">{props.quotes[current].from}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuotesComponent;