import { useEffect, useState } from 'react';
import { ConfigContextConsumer } from "../../utils/configContext";
import Jumbotron from "../../components/jumbotron/jumbotron";
import SlideshowComponent from "../../components/slideshow/slideshow";
import QuotesComponent from "../../components/quotes/quotes";
import ZigZagComponent from "../../components/zig-zag/zig-zag";

import "./homePage.css";

const HomePage = () => {

	const [confSlide, setConfSlide] = useState({ num: 5, margin: 25 });

	useEffect(() => {
		const resizeListener = () => {
			const w = window.innerWidth;
			let num = 5;
			let margin = 25;
			if (w < 400) {
				num = 1;
				margin = 10;
			} else if (w < 750) {
				num = 2;
				margin = 20;
			} else if (w < 950) {
				num = 3;
				margin = 25;
			} else if (w < 1350) {
				num = 4;
				margin = 25;
			}
			setConfSlide({ num, margin });
		};
		resizeListener();
		window.addEventListener('resize', resizeListener);
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	return (
		<div className="homePage">
			<ConfigContextConsumer>
				{context => (
					<>
						<ZigZagComponent
							direction="left"
							videoUrl={context.config["home-video"]}
							template={
								<div className="zigZagText">
									<p dangerouslySetInnerHTML={{ __html: context.labels["home-body"] }}></p>
								</div>
							}
						/>
						<div className={'colSlide-' + confSlide.num}>
							<h1 style={{color: 'rgb(39, 103, 173)', fontSize: '24px', textAlign: 'center', marginTop: '46px'}}>Curriculum Samples</h1>
							<SlideshowComponent
								slidesPerView={confSlide.num}
								spaceBetween={confSlide.margin}
								loop={true}
								autoplay={true}
								content={[
									<img src={context.config["base-img-path"] + context.config["book-page-0"]} alt="knowingscience book" />,
									<img src={context.config["base-img-path"] + context.config["book-page-1"]} alt="knowingscience book" />,
									<img src={context.config["base-img-path"] + context.config["book-page-2"]} alt="knowingscience book" />,
									<img src={context.config["base-img-path"] + context.config["book-page-3"]} alt="knowingscience book" />,
									<img src={context.config["base-img-path"] + context.config["book-page-4"]} alt="knowingscience book" />,
									<img src={context.config["base-img-path"] + context.config["book-page-5"]} alt="knowingscience book" />
								]}
							/>
						</div>
						<ZigZagComponent
							direction="right"
							imgUrl={context.config["base-img-path"] + context.config["img-home-1"]}
							template={
								<div className="zigZagText">
									<h1>{context.labels["home-step1-title"]}</h1>
									<p dangerouslySetInnerHTML={{ __html: context.labels["home-step1-body"] }}></p>
								</div>
							}
						/>
						<QuotesComponent quotes={context.labels["quote"]}></QuotesComponent>
						<ZigZagComponent
							direction="left"
							imgUrl={context.config["base-img-path"] + context.config["img-home-2"]}
							template={
								<div className="zigZagText">
									<h1>{context.labels["home-step2-title"]}</h1>
									<p dangerouslySetInnerHTML={{ __html: context.labels["home-step2-body"] }}></p>
								</div>
							}
						/>
						<ZigZagComponent
							direction="right"
							imgUrl={context.config["base-img-path"] + context.config["img-home-3"]}
							template={
								<div className="zigZagText">
									<h1>{context.labels["home-step3-title"]}</h1>
									<p dangerouslySetInnerHTML={{ __html: context.labels["home-step3-body"] }}></p>
								</div>
							}
						/>
						<ZigZagComponent
							direction="left"
							imgUrl={context.config["base-img-path"] + context.config["img-home-4"]}
							template={
								<div className="zigZagText">
									<h1>{context.labels["home-step4-title"]}</h1>
									<p dangerouslySetInnerHTML={{ __html: context.labels["home-step4-body"] }}></p>
								</div>
							}
						/>
						<Jumbotron />
					</>
				)}
			</ConfigContextConsumer>
		</div>
	);
};

export default HomePage;