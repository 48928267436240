import React, { useState, useRef } from "react";
import axios from 'axios';
import Select from 'react-select';

import configJson from '../../utils/config.json';
import americanStatesJson from '../../utils/american_states.json';

import './contactForm.css';

var qs = require('qs');

const ContactForm = (props) => {
	const [loading, setIsLoading] = useState(false);
	const [alertSuccess, setAlertSuccess] = useState(false);
	const [alertUnSuccess, setAlertUnSuccess] = useState(false);
	const selectRef = useRef();

	const [textAreaMessage, setTextAreaMessage] = useState('');
	const [fullName, setFullName] = useState('');
	const [emailAddress, setEmailAddress] = useState('');
	const [subjectEmail, setSubjectEmail] = useState('');
	const [americanStateValue, setAmericanStateValue] = useState('');
	const [schoolAddress, setSchoolAddress] = useState('');
	const [schoolCity, setSchoolCity] = useState('');
	const [zipCode, setZipCode] = useState('');

	const sendEmailRequest = (event) => {
		event.preventDefault();
		setIsLoading(true)

		var data = qs.stringify({
			'name': fullName,
			'address': emailAddress,
			'subject': subjectEmail,
			'message': textAreaMessage,
			's-address': schoolAddress,
			's-state': americanStateValue,
			's-city': schoolCity,
			's-zip-code': zipCode
		});
		var config = {
			method: 'post',
			url: configJson.endpoints.config,
			headers: {
				'Ocean-Component': 'mail',
				'Ocean-Method': 'insert',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: data
		};
		axios(config)
			.then(response => {
				setIsLoading(false);
				if (response.data.status !== "OK") {
					setAlertUnSuccess(true);
				}
				else {
					setAlertSuccess(true)
				}

			}).catch((e) => {
				setAlertUnSuccess(true);
				setIsLoading(false);
			})

		return false;
	}

	const handleChangeTextArea = (event) => {
		setTextAreaMessage(event.target.value);
	}

	const handleSubjectEmail = (event) => {
		setSubjectEmail(event.target.value);
	}

	const handleEmailAddress = (event) => {
		setEmailAddress(event.target.value);
	}

	const handleFullName = (event) => {
		setFullName(event.target.value);
	}

	const handleAlertClosed = (event) => {
		setAlertSuccess(false);
		setAlertUnSuccess(false);
	}

	const handleSelectState = (event) => {
		selectRef.current.value = 'x';
		setAmericanStateValue(event.value);
	}

	const handleSchoolAddress = (event) => {
		setSchoolAddress(event.target.value);
	}

	const handleSchoolCity = (event) => {
		setSchoolCity(event.target.value);
	}

	const handleZipCode = (event) => {
		setZipCode(event.target.value);
	}

	const options = americanStatesJson.map((elem, i) => {
		return { value: elem.name, label: elem.name };
	});

	return (
		<div className="w3-container w3-padding-64 contactform component-std" id="contact" ref={props.contactRef}>
			<h1 className="title-form">Contact Form</h1>
			{
				alertSuccess ?
					<div className="w3-panel w3-green w3-display-container">
						<span onClick={handleAlertClosed} className="w3-button w3-large w3-display-topright exitbtn">×</span>
						<h3>Success!</h3>
						<p>E-mail successfully sent.</p>
					</div>
					: alertUnSuccess ?
						<div className="w3-panel w3-red w3-display-container">
							<span onClick={handleAlertClosed} className="w3-button w3-large w3-display-topright exitbtn">×</span>
							<h3>Alert!</h3>
							<p>E-mail not sent. An error occurred, please try again later.</p>
						</div>
						: null
			}
			<form onSubmit={sendEmailRequest} target="_blank">
				<div className="input-container">
					<i className="fas fa-user" />
					<input className="w3-input w3-padding-16" type="text" placeholder="Full name" onChange={handleFullName} required={true} />
				</div>
				<div className="input-container">
					<i className="fas fa-envelope" />
					<input className="w3-input w3-padding-16" type="email" placeholder="email" onChange={handleEmailAddress} required={true} />
				</div>
				<div className="input-container">
					<i className="fas fa-edit" />
					<input className="w3-input w3-padding-16" type="text" placeholder="Subject" required={true} onChange={handleSubjectEmail} />
				</div>
				<h3 className="title-form sub">School Address</h3>
				<div className="address-container">
					<div className="input-container">
						<i className="fas fa-map-pin" />
						<input className="w3-input w3-padding-16" type="text" placeholder="Address" required={true} onChange={handleSchoolAddress} />
					</div>
					<div className="input-container">
						<i className="fas fa-address-card" />
						<input className="w3-input w3-padding-16" type="text" placeholder="Zip Code" required={true} onChange={handleZipCode} />
					</div>
				</div>
				<div className="city-container">
					<div className="input-container">
						<i className="fas fa-city" />
						<input className="w3-input w3-padding-16" type="text" placeholder="City" required={true} onChange={handleSchoolCity} />
					</div>
					<div className="input-container">
						<i className="fas fa-flag-usa" />
						<div style={{display: 'flex', width: 'calc(100% - 30px)', flexDirection: 'column'}}>
							<Select
								onChange={handleSelectState}
								className="custom-select"
								options={options}
								placeholder="Select State..."
								required
							/>
							<input ref={selectRef} required={true} style={{height: '1px', padding: '0', border: '0', opacity: '0'}}/>
						</div>
					</div>
				</div>
				<h3 className="title-form sub">Message</h3>
				<textarea id="w3review" name="w3review" rows="4" onChange={handleChangeTextArea} required={true} />
				<button className="w3-button w3-section button" type="submit" disabled={loading}>
					{
						loading ?
							<div className="load-wrapp">
								<div className="load-2">
									<div className="line"></div>
									<div className="line"></div>
									<div className="line"></div>
								</div>
							</div>
							:
							<>
								<div className="anim"></div>
								<span>send message</span>
							</>
					}
				</button>
			</form>
		</div>
	)
};

export default ContactForm;