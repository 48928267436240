import './zig-zag.css';
import VideoComponent from "../video-component/video-component";

const ZigZagComponent = (props) => {

	const className = props.direction === 'left' ? 'row-left' : 'row-right';

	const getImage = () => {
		return (
			<div className="column-33">
				{
					props.imgUrl ?
						<img className="image" src={props.imgUrl} alt="zig-zag" />
						:
						<VideoComponent videoUrl={props.videoUrl} />
				}
			</div>
		);
	};

	return (
		<section className="zig-zag container component-std">
			<div className={`row ${className}`}>
				{
					props.direction === 'left' ? getImage() : null
				}
				<div className="column-66">{props.template}</div>
				{
					props.direction === 'left' ? null : getImage()
				}
			</div>
		</section>
	);

};

export default ZigZagComponent;