import './App.css';
import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import AppDesktop from './AppDesktop';
import { Helmet } from 'react-helmet'
import { ConfigContextConsumer } from "./utils/configContext";
import axios from 'axios';
import { useLocalStorage } from 'react-use-storage';

const App = () => {
	const [loginObject, setLoginObject, removeValue] = useLocalStorage("loginObject", localStorage.getItem("loginObject") && JSON.parse(localStorage.getItem("loginObject")).token !== undefined ? localStorage.getItem("loginObject") : {});

	axios.interceptors.response.use(function (res) {
		const currentDate = new Date();
		const timestamp = currentDate.getTime();
		if (res.headers['ocean-renew'] && (!loginObject.last || timestamp > (loginObject.last + 2500))) {
			if (loginObject.token !== res.headers['ocean-renew']) {
				loginObject.token = res.headers['ocean-renew'];
				loginObject.last = timestamp;
				setLoginObject(loginObject);
			}
		}
		return res;
	}, function (err) {
		return Promise.reject(err);
	})

	return (
		<ConfigContextConsumer>
			{context => (
				<Router>
					<Helmet>
						<title>{context.title}</title>
						<meta name="description" content={context.description} />
					</Helmet>
					<AppDesktop context={context} />
				</Router>
			)}
		</ConfigContextConsumer>
	)
}

export default App;