import axios from "axios";
import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use-storage";
import Spinner from "../../components/spinner/spinner";
import AttachmentModalComponent from "../../components/attachmentModal/attachmentModal";
import config from '../../utils/config.json';
import "./lessonDetailPage.css"


const LessonDetailPage = ({ match }) => {
    const {
        params: { lessonId }
    } = match;

    const [userData] = useLocalStorage('loginObject', {});
    const [lesson, setLesson] = useState({});
    const [loading, setIsLoading] = useState(true);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        let configReq = {
            headers: {
                "Ocean-Component": "lesson",
                "Ocean-Method": "select",
                "Ocean-Result": "json",
                "Ocean-User": userData.username,
                "Ocean-Token": userData.token
            },

            params: {
                id: lessonId
            }
        }

        let configRepo = {
            headers: {
                "Ocean-Component": "repo",
                "Ocean-Method": "select",
                "Ocean-Result": "json",
                "Ocean-User": userData.username,
                "Ocean-Token": userData.token
            },

            params: {
                lesson: lessonId
            }
        }
        const requestLessonDetail = axios.get(config.endpoints.config, configReq);

        requestLessonDetail.then(res => {
            let tempLesson = res.data;
            const requests = [];
            if (res.data.attachments && res.data.attachments.length > 0) {
                res.data.attachments.forEach(a => {
                    configRepo.params.id = a;
                    const requestRepo = axios.get(config.endpoints.config, configRepo)
                    requests.push(requestRepo);
                });
                axios.all(requests).then(res => {
                    tempLesson.realAttachments = res.map(r => {
                        return {
                            ...r.data,
                            // id: configRepo.params.id,
                            lessonId: lessonId
                        }
                    });
                    setLesson(tempLesson);
                    setIsLoading(false);
                }).catch(errors => {
                });
            } else {
                setLesson(tempLesson);
                setIsLoading(false);
            }
        })
    };

    if (lesson) {
        let $ss = lesson['state-standards'];
        if ($ss && $ss !== ';;;' && $ss.length > 0) {
            $ss = $ss.substring(3, $ss.length - 3).split(";;;").map((ss, idx) => {
                const [$state, $standard] = ss.split('--');
                return <li key={'lesson-page-standards-' + idx}>{$standard} ({$state})</li>
            });
        } else {
            $ss = [];
        }

        return (
            loading ? <Spinner></Spinner>
                :

                <div className="margin-top-page w3-container component-std lessonPage">
                    <AttachmentModalComponent type="desktop" userData={userData} lesson={lessonId} onClose={closeModal} showModal={showAttachmentModal} content={modalContent}></AttachmentModalComponent>
                    <h1>{lesson.title}</h1>

                    <div className="feautures">
                        <span>Grade: <b>{lesson.grade}</b></span>
                        <span className="sp" />
                        <span>Discipline: <b>{lesson.discipline}</b></span>
                        <span className="sp" />
                        <span>Unit: <b>{lesson.unit}</b></span>
                        <span className="sp" />
                        <span>Lesson: <b>{lesson.lesson}</b></span>
                    </div>

                    <div className="w3-row" dangerouslySetInnerHTML={{ __html: lesson.description }} />
                    {
                        $ss.length > 0 ? (
                            <div className="w3-row">
                                <p>Standards:</p>
                                <ul className="w3-col">{$ss}</ul>
                            </div>
                        ) : null
                    }

                    <div className="w3-row attachments">
                        <h3>Attachments <small>{lesson.realAttachments && lesson.realAttachments.length ? lesson.realAttachments.length : 0}</small></h3>
                        <Attachments attachments={lesson.realAttachments}></Attachments>
                    </div>
                </div>
        );
    }

    function Attachments({ attachments }) {
        if (attachments && attachments.length > 0) {
            const newAtt = attachments.sort((a, b) => {
                const nA = parseInt(a.order, 10);
                const nB = parseInt(b.order, 10);
                if (nA < nB) return -1;
                if (nA > nB) return 1;
                return 0;
            });
            if (newAtt.length > 0) {
                return (
                    <ul>
                        {
                            newAtt.map((a, idx) => handleAttachment(a, idx))
                        }
                    </ul>
                );
            }
        }
        return <></>;
    }


    function handleAttachment(attachment, idx) {
        switch (attachment.type) {
            case 'LINK':
                return <li key={'lesson-attachment-' + idx}><a href={attachment.content} target="_blank"><i className="fas fa-external-link-alt"></i> {attachment.title}</a> </li>
            case 'VIDEO':
                return <li key={'lesson-attachment-' + idx}><a href="" onClick={e => openModal(e, attachment)}><i className="fas fa-video"></i> {attachment.title}</a></li>
            case 'HTML':
                return <li key={'lesson-attachment-' + idx}><a href="" onClick={e => getHTMLAndOpenModal(e, attachment)}><i className="fas fa-flask"></i> {attachment.title}</a></li>
            case 'FILE':
                let URL = config.endpoints.uploads + attachment.content;
                return <li key={'lesson-attachment-' + idx}><a href={URL} target="_blank"><i className="fas fa-file"></i> {attachment.title}</a></li>
            case 'IMGS':
                return <li key={'lesson-attachment-' + idx}><a href="" onClick={e => getImagesAndOpenModal(e, attachment)}><i className="fas fa-images"></i> {attachment.title}</a></li>
        }
    }


    function openModal(e, props) {
        e.stopPropagation();
        e.preventDefault();
        setModalContent(props);
        setShowAttachmentModal(true);
    }

    function getHTMLAndOpenModal(e, attachment) {
        e.stopPropagation();
        e.preventDefault();
        attachment.codePage = config.endpoints.code + '?id=' + attachment.id;
        openModal(e, attachment);
    }


    function getImagesAndOpenModal(e, attachment) {
        e.stopPropagation();
        e.preventDefault();
        let requests = [];
        let configImg = {

            headers: {
                "Ocean-Component": "repo",
                "Ocean-Method": "download",
                "Ocean-Result": "json",
                "Ocean-User": userData.username,
                "Ocean-Token": userData.token
            },

            params: {
                lesson: lessonId,
                id: attachment.id
            }
        }

        attachment.content.forEach(id => {
            configImg.params.img = id;
            const requestImg = axios.get(config.endpoints.config, configImg);
            requests.push(requestImg);
        })

        axios.all(requests).then(res => {
            attachment.galleryImages = res.map(r => r.data);
            openModal(e, attachment);
        })

    }

    function closeModal() {
        setShowAttachmentModal(false);
    }
}

export default LessonDetailPage;