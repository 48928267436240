import { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../components/spinner/spinner';
import { changePage } from '../../utils/routes';
import { ConfigContextConsumer } from '../../utils/configContext';
import { get } from '../../utils/httpRequests';

import './search.css';


const SearchPage = (props) => {

    const [loading, setIsLoading] = useState(true);
    const [result, setResult] = useState(true);
    const configContext = useContext(ConfigContextConsumer);

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (props.location.state.body.searchValue !== undefined) {
            setIsLoading(true)
            get('list', 'search', {
                query: props.location.state.body.searchValue
            }).then((response) => {
                const porpoToRoute = {
                    context: configContext,
                    history: props.history
                }
                let parsedResponse = []
                response.data.forEach((element, idx) => {
                    parsedResponse.push(
                        <div key={'key-search-data-' + idx} className="card-wrapper w3-col s4 w3-center">
                            <div className="card">
                                <div className="container">
                                    <h2>{element.title}<span>page</span></h2>
                                    <p>{element.description}</p>
                                    <button className="w3-button w3-section button" onClick={() => { changePage(porpoToRoute, '/dynamic', { body: element.body }, element.title, element.description) }}>
                                        <div className="anim"></div>
                                        <span><i className="fas fa-link" /> Go to page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                });
                setResult(parsedResponse);
                setIsLoading(false)
            }).catch((error) => {
            });
        }

    }, [props.location.state.body]);

    return (
        <div className='component-std' style={{ minHeight: '100vh' }}>
            {
                loading ?
                    <Spinner />
                    :
                    (
                        result.length > 0 ?
                            <div className='searchResult'>
                                <p className='title'>Search result</p>
                                <div className='containerSearchResult w3-row'>{result}</div>
                            </div>
                            : <div className="searchResult noResult">we are sorry but for this search key there are no results</div>
                    )
            }
        </div>
    )
};

export default withRouter(SearchPage);