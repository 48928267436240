import './spinner.css';
import img1Spinner from '../../assets/spinner/imgSpinner1.svg';
import img2Spinner from '../../assets/spinner/imgSpinner2.svg';

const Spinner = () => {

    return (
        <div className='containerSpinner'>
            <img className='img1Spinner' src={img1Spinner} alt="spinner knowing science" />
            <img className='img2Spinner' src={img2Spinner} alt="spinner knowing science" />
        </div>
    );

};

export default Spinner;