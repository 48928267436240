import { Link } from 'react-router-dom';
import config from '../../config.json';

import G1ESS from '../../assets/lessons/G1ESS_150.jpg';
import G1LS from '../../assets/lessons/G1LS_150.jpg';
import G1PS from '../../assets/lessons/G1PS_150.jpg';
import G2ESS from '../../assets/lessons/G2ESS_150.jpg';
import G2LS from '../../assets/lessons/G2LS_150.jpg';
import G2PS from '../../assets/lessons/G2PS_150.jpg';
import G3ESS from '../../assets/lessons/G3ESS_150.jpg';
import G3LS from '../../assets/lessons/G3LS_150.jpg';
import G3PS from '../../assets/lessons/G3PS_150.jpg';
import G4ESS from '../../assets/lessons/G4ESS_150.jpg';
import G4LS from '../../assets/lessons/G4LS_150.jpg';
import G4PS from '../../assets/lessons/G4PS_150.jpg';
import G5ESS from '../../assets/lessons/G5ESS_150.jpg';
import G5LS from '../../assets/lessons/G5LS_150.jpg';
import G5PS from '../../assets/lessons/G5PS_150.jpg';
import G6ESS from '../../assets/lessons/G6ESS_150.jpg';
import G6LS from '../../assets/lessons/G6LS_150.jpg';
import G6PS from '../../assets/lessons/G6PS_150.jpg';
import GKESS from '../../assets/lessons/GKESS_150.jpg';
import GKLS from '../../assets/lessons/GKLS_150.jpg';
import GKPS from '../../assets/lessons/GKPS_150.jpg';
import GPKESS from '../../assets/lessons/GPKESS_150.jpg';
import GPKLS from '../../assets/lessons/GPKLS_150.jpg';
import GPKPS from '../../assets/lessons/GPKPS_150.jpg';
import './lessonCard.css';

const getImgHEader = (grade, discipline) => {
    grade = grade.toLowerCase();
    if (discipline === 'ESS') {
        if (grade == '1') return G1ESS;
        if (grade == '2') return G2ESS;
        if (grade == '3') return G3ESS;
        if (grade == '4') return G4ESS;
        if (grade == '5') return G5ESS;
        if (grade === 'pre-kindergarten') return GPKESS;
        if (grade === 'kindergarten') return GKESS;
        if (grade === 'middle school') return G6ESS;
    } else if (discipline === 'LS') {
        if (grade == '1') return G1LS;
        if (grade == '2') return G2LS;
        if (grade == '3') return G3LS;
        if (grade == '4') return G4LS;
        if (grade == '5') return G5LS;
        if (grade === 'pre-kindergarten') return GPKLS;
        if (grade === 'kindergarten') return GKLS;
        if (grade === 'middle school') return G6LS;
    } else {
        if (grade == '1') return G1PS;
        if (grade == '2') return G2PS;
        if (grade == '3') return G3PS;
        if (grade == '4') return G4PS;
        if (grade == '5') return G5PS;
        if (grade === 'pre-kindergarten') return GPKPS;
        if (grade === 'kindergarten') return GKPS;
        if (grade === 'middle school') return G6PS;
    }
};

const LessonCardComponent = (props) => {

    const attachments = props.lesson.attachments.substring(3, props.lesson.attachments.length - 3).split(';;;').length;

    const getDescription = () => {
        let desc = props.lesson.description.replace(/(<([^>]+)>)/gi, "");
        if (desc.length > 120) desc = desc.substring(0, 117) + '...';
        return desc;
    }

    return (
        <div className="lesson-card">
            <div className="content">
                <h1>{props.lesson.title}</h1>
            </div>
            <img src={getImgHEader(props.lesson.grade, props.lesson.discipline)} alt="lesson" />
            <div className="content">
                <p>{getDescription()}</p>
                <Link className="w3-button w3-section button" to={`${(config.root ? '' : config.home)}/lessons/${props.lesson.id}`}>
                    <div className="anim"></div>
                    <span>Show Lesson</span>
                </Link>
                <div className="attachments">
                    <hr />
                    <div className="attach-indicators">
                        <span><i className="fas fa-graduation-cap" /> {props.lesson.grade}</span>
                    </div>
                    <div className="attach-indicators">
                        <span><i className="fas fa-book" /> {props.lesson.discipline}</span>
                    </div>
                    <div className="attach-indicators">
                        <span><i className="fas fa-sort-amount-up-alt" /> Unit {props.lesson.unit}</span>
                        <span><i className="fas fa-tags" /> Lesson {props.lesson.lesson}</span>
                    </div>
                    <div className="attach-indicators">
                        <span><i className="fas fa-paperclip" /> {attachments} Attachment{attachments.length === 1 ? '' : 's'}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LessonCardComponent;