import React, { Component } from "react";
const { Provider, Consumer } = React.createContext();

class ConfigContextProvider extends Component {
  state = {
    config: {},
    labels: {},
    isTablet: false,
    isPhone: false,
    isDesktop: false,
    title: '',
    description: '',
    login:{}
  };

  setGlobalConfig = (configJson) => {
    this.setState({
        config: configJson
    })
  };

  setGlobalLabels = (labelsJson) => {
    this.setState({
      labels: labelsJson
    })
  };

  setIsTablet = (isTablet) => {
    this.setState({
      isTablet: isTablet
    })
  }

  setIsPhone = (isPhone) => {
    this.setState({
      isPhone: isPhone
    })
  }

  setIsDesktop = (isDesktop) => {
    this.setState({
      isDesktop: isDesktop
    })
  }

  setDescription = (description) => {
    this.setState({
      description: description
    })
  }

  setTitle = (title) => {
    this.setState({
      title: title
    })
  }

  setLoginObject = (loginObject) => {
    this.setState({
      login: loginObject
    })
  };

  render() {
    return (
      <Provider
        value={{ 
          config: this.state.config, 
          labels: this.state.labels, 
          isTablet: this.state.isTablet,
          isPhone: this.state.isPhone, 
          isDesktop: this.state.isDesktop,
          title: this.state.title,
          description: this.state.description,
          setIsDesktop: this.setIsDesktop, 
          setIsPhone: this.setIsPhone, 
          setIsTablet: this.setIsTablet, 
          setGlobalLabels: this.setGlobalLabels, 
          setGlobalConfig: this.setGlobalConfig,
          setDescription: this.setDescription,
          setTitle: this.setTitle
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export { ConfigContextProvider, Consumer as ConfigContextConsumer };
