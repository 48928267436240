import ReactPlayer from 'react-player/youtube'
import VisibilitySensor from 'react-visibility-sensor';

import React, { useState } from "react";

import "./video-component.css";


const VideoComponent = (props) => {
	const [playing, setIsPlaying] = useState(true);
	
	const opts = {
		youtube: {
			rel: 0,
			color: 'white',
			controls: 1,
			fs: 0,
			preload: true
		}
	};

	return (
		<VisibilitySensor onChange={setIsPlaying}>
			<div className="player-wrapper">
				<ReactPlayer
					url={`https://www.youtube.com/embed/${props.videoUrl}`}
					config={opts}
					playing={playing}
					loop={true}
					controls={true}
					className="video-iframe"
					autoPlay={true}
					muted={true}
				/>
			</div>
		</VisibilitySensor>
	);

};

export default VideoComponent;
