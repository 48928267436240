import React from "react";
import { Link } from 'react-router-dom';
import './standardsList.css';
import states from '../../utils/american_states.json';
import config from '../../config.json';

const StandardsList = (props) => {

    const getFeature = (elem, type) => {
        let ret = [];
        if (elem && elem.feature) {
            ret = elem.feature.filter(el => el.type === type).map(el => {
                return {
                    ...el,
                    content: el.content ? el.content.substr(3, el.content.length - 6).split(';;;') : []
                };
            });
        }
        return ret;
    };

    const getCodeState = st => {
        let code = '';
        if (st === 'National - NGSS') {
            return 'NGSS';
        }
        for (let i = 0; i < states.length; i++) {
            if (states[i].name === st) {
                code = states[i].abbreviation;
                break;
            }
        }
        return code;
    };

    return (
        props.standards.length === 0 ? <div style={{
            width: '100%',
            display: 'flex', justifyContent: 'center',
            backgroundColor: 'lightblue'
        }}> <h2>No Standards Found</h2></div>
            :
            <>
                {props.standards.map((s, idx) => {
                    return <div className="w3-row w3-container standards-container component-std" key={'standards-result-' + idx}>
                        <h1 className="standard-header">{getCodeState(s.state)} {s.code} {s.topic}</h1>
                        <div>
                            <p>Students who demonstrate understanding can:</p>
                            <p>
                                <b>{s.pe_code}:  {s.pe_content} </b>
                                {
                                    s.clarification_statement ?
                                        <span style={{ color: 'red' }}> [Clarification Statement: {s.clarification_statement}]</span>
                                        : null
                                }
                                {
                                    s.assessment_boundary ?
                                        <i style={{ color: 'red' }}> [Assessment Boundary: {s.assessment_boundary}]</i>
                                        : null
                                }

                            </p>
                        </div>
                        <div className="w3-row">
                            <div className="w3-third sep-container feature-container">
                                <h3>Science and Engineering Practices</h3>
                                {
                                    getFeature(s, 'SEP').map((el, i) => {
                                        return <p key={'standard-sep-' + i}>
                                            <b>{el.code ? el.code + ': ' : ''}{el.title}</b>
                                            <ul>
                                                {
                                                    el.content.map((sub, jdx) => {
                                                        return <li key={'standard-sep-li-' + jdx}>{sub}</li>
                                                    })
                                                }
                                            </ul>
                                        </p>;
                                    })
                                }
                            </div>
                            <div className="w3-third dci-container feature-container">
                                <h3 style={{ backgroundColor: 'orange', color: 'white' }}>
                                    Disciplinary Core Ideas
                                </h3>
                                {
                                    getFeature(s, 'DCI').map((el, i) => {
                                        return <p key={'standard-dci-' + i}>
                                            <b>{el.code ? el.code + ': ' : ''}{el.title}</b>
                                            <ul>
                                                {
                                                    el.content.map((sub, jdx) => {
                                                        return <li key={'standard-dci-li-' + jdx}>{sub}</li>
                                                    })
                                                }
                                            </ul>
                                        </p>;
                                    })
                                }
                            </div>
                            <div className="w3-third ccc-container feature-container">
                                <h3 style={{ backgroundColor: 'green', color: 'white' }}>
                                    Crosscutting Concepts
                                </h3>
                                {
                                    getFeature(s, 'CCC').map((el, i) => {
                                        return <p key={'standard-ccc-' + i}>
                                            <b>{el.code ? el.code + ': ' : ''}{el.title}</b>
                                            <ul>
                                                {
                                                    el.content.map((sub, jdx) => {
                                                        return <li key={'standard-ccc-li-' + jdx}>{sub}</li>
                                                    })
                                                }
                                            </ul>
                                        </p>;
                                    })
                                }
                            </div>
                        </div>
                        {
                            s.list && s.list.length > 0 ? (
                                <>
                                    <b>Lessons List of {s.pe_code} standard:</b>
                                    <ul>
                                        {
                                            s.list.map((eListElem, idxElem) => {
                                                if (typeof eListElem !== 'string') {
                                                    return (
                                                        <li key={'lesson-list-' + idx + '-' + idxElem}>
                                                            Lesson {eListElem.unit}.{eListElem.lesson} <Link className="btn btn-link" to={`${(config.root ? '' : config.home)}/lessons/${eListElem.id}`}>{eListElem.title}</Link>, Grade {eListElem.grade} {eListElem.discipline}
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li key={'lesson-list-' + idx + '-' + idxElem}>{eListElem}</li>
                                                    );
                                                }
                                            })
                                        }
                                    </ul>
                                </>
                            ) : null
                        }
                    </div>
                })}
            </>
    )
}


export default StandardsList