import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { changePage } from '../../utils/routes';

import "./footer.css";

const Footer = (props) => {

	const [showScroll, setShowScroll] = useState(false);
	const [heightFooter, setHeightFooter] = useState(0);
	const footerDiv = useRef(null);

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const checkScrollTop = () => {
		if (window.pageYOffset > 1) {
			setShowScroll(true);
		} else if (window.pageYOffset <= 1) {
			setShowScroll(false);
		}
	};

	const resizeListener = () => {
		if (footerDiv.current) {
			const h = footerDiv.current.clientHeight;
			if (h !== heightFooter) {
				setHeightFooter(h);
			}
		}
	};

	useEffect(() => {
		resizeListener();
		window.addEventListener('resize', resizeListener);
		window.addEventListener('scroll', checkScrollTop);
		return () => {
			window.removeEventListener('resize', resizeListener);
			window.removeEventListener('scroll', checkScrollTop);
		};
	}, []);

	const parseStickyMenuResponse = () => {
		const elements = [];
		props.stickyMenuResponse.forEach((menuItem, index) => {
			if (index > 0) {
				if (menuItem.havesubmenu && menuItem.havesubmenu !== "0") { }
				else {
					elements.push(<a key={`key-footer-${index}`} onClick={() => {
						if (menuItem.type === 'static') {
							changePage(props, '/' + menuItem.slug, undefined, menuItem.title, menuItem.description);
						} else {
							changePage(props, '/dynamic', { body: menuItem.body }, menuItem.title, menuItem.description);
						}
					}} className={''}>{menuItem.title}</a>);
				}
			}
		});
		elements.push(
			<a key="key-footer-contact" onClick={() => { changePage(props, '/contact', undefined, 'contact') }} className={'static'}>Contact</a>
		);
		return elements;
	}

	const printFooterMenu = () => {
		// let elements = [<div key="key-footer-m-main" className="copyright">&copy; {1900 + new Date().getYear()}{" "} Knowing Science LLC<br />Powered by <a href="https://www.bigbluesolution.com" target="_blank">Team Ocean</a></div>];
		let elements = [<div key="key-footer-m-main" className="copyright">&copy; {1900 + new Date().getYear()}{" "} Knowing Science LLC</div>];
		props.menuResponse.forEach((item, index) => {
			elements.push(
				<div key={`key-footer-m-${index}`} className="footer-menu-item">
					<a className="links" onClick={() => {
						changePage(props, '/dynamic', { body: item.body }, item.title, item.description);
					}}
						style={{ fontSize: "16px", textTransform: "uppercase" }}>
						{item.title}
					</a>
				</div>
			);
		});

		return elements;
	};

	return (
		<>
			<div style={{height: heightFooter}}></div>
			<div className="component-full footer-full" ref={footerDiv}>
				<div>
					<button
						id="scrollTopBtn"
						className={showScroll ? "scrollTop showBtn" : "scrollTop"}
						onClick={scrollTop}
					>
						<i className="fa fa-arrow-up" />
					</button>
					<footer className="w3-row-padding w3-padding-32">
						<div className="w3-third" style={{ paddingLeft: "32px" }}>
							<img
								style={{ maxWidth: "100%" }}
								src={props.context.config['base-img-path'] + props.context.config['ks-logo']}
								alt="Home"
							/>
						</div>
						<div className="w3-third footer sidepanelFooter">{parseStickyMenuResponse()}</div>
						<div className="w3-third w3-serif">
							<p>KNOWING SCIENCE LLC<br />Email: <a href="mailto:info@knowingscience.com">info@knowingscience.com</a>
							</p>
						</div>
						<div className="w3-mobile">
							<a href="https://www.facebook.com/knowingscience" target="_blank" >
								<i className="fab fa-facebook footer-social" />
							</a>
							<a href="https://twitter.com/knowing_science" target="_blank">
								<i className="fab fa-twitter footer-social" />
							</a>
							<a href="https://www.linkedin.com/company/knowing-science" target="_blank">
								<i className="fab fa-linkedin footer-social" />
							</a>
						</div>
					</footer>
					<div className="row service-menu">{printFooterMenu()}</div>
					<div className="w3-row footer">
						<div
							className="w3-col m12"
							style={{ paddingLeft: "15px", paddingRight: "15px" }}
						>
							<p className="copyright-text">All Rights Reserved. Kid Knowledge, STEM Knowledge, STEM Kit, Eco Cube, Knowing Math, and Knowing Science are registered trademarks of Knowing Science LLC. Patents Pending.</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);

};

export default withRouter(Footer);