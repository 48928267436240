import { Route } from 'react-router-dom';
import HomePage from '../pages/homePage/homePage';
import DynamicPage from '../pages/dynamic/dynamicPage';
import ContactPage from '../pages/contactPage/contactPage';
import StandardsPage from '../pages/standardsPage/standardsPage';
import LessonsPage from '../pages/lessonsPage/lessonsPage';
import SearchPage from '../pages/searchPage/search';
import LessonDetailPage from '../pages/lessonDetailPage/lessonDetailPage';
import TeacherResourcesPage from '../pages/teacherResourcesPage/teacherResourcesPage';
import config from '../config.json';

const Routes = () => {
    return (
        <>
            <Route path={(config.root ? '' : config.home) + '/'} component={HomePage} exact />
            <Route path={(config.root ? '' : config.home) + '/lessons' } component={LessonsPage} exact />
            <Route path={(config.root ? '' : config.home) + '/standards' } component={StandardsPage} exact />
            <Route path={(config.root ? '' : config.home) + '/dynamic' } component={DynamicPage} exact />
            <Route path={(config.root ? '' : config.home) + '/contact' } component={ContactPage} exact />
            <Route path={(config.root ? '' : config.home) + '/search' } component={SearchPage} exact />
            <Route path={(config.root ? '' : config.home) + '/teacherresources' } component={TeacherResourcesPage} exact />
            <Route path={(config.root ? '' : config.home) + '/lessons/:lessonId' } exact component={LessonDetailPage} />
        </>
    );

};

export const changePage = (props, pathname, state = undefined, title = '', description = '') => {
    const capitalizeFirstLetter = s => {
        return s.split(' ').map(el => {
            return el.charAt(0).toUpperCase() + el.slice(1).toLowerCase();
        }).join(' ');
    };
    if (title !== '') {
        title = capitalizeFirstLetter(title) + ' | Knowing Science';
    } else {
        title = 'Knowing Science';
    }
    props.context.setDescription(description);
    props.context.setTitle(title);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    props.history.push({ pathname: (config.root ? '' : config.home) + pathname, state });
};

export default Routes;