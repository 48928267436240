import React, { useState } from "react";
import Select from 'react-select';

import americanStatesJson from '../../utils/american_states.json';
import optionsGrade from '../../utils/grade.json';
import optionsDiscipline from '../../utils/discipline.json';

import './standardsForm.css';

var qs = require('qs');

const StandardsForm = (props) => {
	const [loading, setIsLoading] = useState(false);

	const [americanStateValue, setAmericanStateValue] = useState('');
	const [gradeValue, setGradeValue] = useState('');
	const [disciplineValue, setDisciplineValue] = useState('');
	const [pecode, setPecode] = useState('');
	const [unit, setUnit] = useState('');
	const [lesson, setLesson] = useState('');
	const [alertUnSuccess, setAlertUnSuccess] = useState(false);

	const handleSelectState = (event) => {
		setAmericanStateValue(event.value);
	};

	const handleSelectGrade = (event) => {
		setGradeValue(event && event.value ? event.value : null);
	};

	const handleSelectDiscipline = (event) => {
		setDisciplineValue(event && event.value ? event.value : null);
	};

	const handlePeCode = (event) => {
		setPecode(event.target.value);
	};

	const handleUnit = (event) => {
		setUnit(event.target.value);
	};

	const handleLesson = (event) => {
		setLesson(event.target.value);
	};

	const optionsState = [
		{ value: 'National - NGSS', label: 'National - NGSS' },
		...americanStatesJson.map((elem, i) => {
			return { value: elem.name, label: elem.name };
		})
	];

	const searchParameters = (event) => {
		event.preventDefault();
		if ((americanStateValue && pecode) || (americanStateValue && gradeValue && disciplineValue && unit && lesson)) {
			props.searchButtonCallback({
				state: americanStateValue,
				code: pecode,
				grade: gradeValue,
				discipline: disciplineValue,
				unit: unit,
				lesson: lesson
			});
		} else {
			setAlertUnSuccess(true);
		}
	};

	const handleAlertClosed = (event) => {
		setAlertUnSuccess(false);
	}

	return (
		<div className="w3-container w3-padding-64 standardsform component-std" id="contact" ref={props.contactRef}>
			<h1 className="title-form">Standards Search</h1>
			<p className="intro-form">Search by entering your State (or NGSS) and the Standard’s Performance Expectation code. Alternatively, search by lesson: enter your State (or NGSS), Grade, Discipline, Unit, and Lesson number.</p>
			{
				alertUnSuccess ?
					<div className="w3-panel w3-red w3-display-container">
						<span onClick={handleAlertClosed} className="w3-button w3-large w3-display-topright exitbtn">×</span>
						<h3>Alert!</h3>
						<p>Insert missing information.</p>
					</div>
					: null
			}
			<form onSubmit={searchParameters}>
				<div className="input2-container">
					<div className="input-container">
						<i className="fas fa-flag-usa" />
						<Select
							onChange={handleSelectState}
							className="custom-select"
							options={optionsState}
							placeholder="Select State..."
						/>
					</div>
					<div className="input-container">
						<i className="fas fa-code" />
						<input className="w3-input w3-padding-16" type="text" placeholder="Code" onChange={handlePeCode} />
					</div>
				</div>
				<div className="input2-container">
					<div className="input-container">
						<i className="fas fa-graduation-cap" />
						<Select
							onChange={handleSelectGrade}
							className="custom-select"
							options={optionsGrade}
							placeholder="Select Grade..."
							isClearable={true}
						/>
					</div>
					<div className="input-container">
						<i className="fas fa-tag" />
						<Select
							onChange={handleSelectDiscipline}
							className="custom-select"
							options={optionsDiscipline}
							placeholder="Select Discipline..."
							isClearable={true}
						/>
					</div>
				</div>
				<div className="input2-container">
					<div className="input-container">
						<i className="fas fa-clipboard-list" />
						<input className="w3-input w3-padding-16" type="text" placeholder="Unit" onChange={handleUnit} />
					</div>
					<div className="input-container">
						<i className="fas fa-chalkboard-teacher" />
						<input className="w3-input w3-padding-16" type="text" placeholder="Lesson" onChange={handleLesson} />
					</div>
				</div>
				<button className="w3-button w3-section button raised hoverable" type="submit" disabled={loading}>
					{
						loading ?
							<div className="load-wrapp">
								<div className="load-2">
									<div className="line"></div>
									<div className="line"></div>
									<div className="line"></div>
								</div>
							</div>
							:
							<>
								<div className="anim"></div>
								<span >search</span>
							</>
					}
				</button>
			</form>
		</div>
	);
};

export default StandardsForm;