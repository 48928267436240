import React from 'react';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import "./slideshow.css";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

const SlideshowComponent = (props) => {

	return (
		<Swiper
			spaceBetween={props.spaceBetween}
			slidesPerView={props.slidesPerView}
			loop={props.loop}
			loopFillGroupWithBlank={props.loopFillGroupWithBlank}
			autoplay={props.autoplay ? {
				delay: 5000,
				disableOnInteraction: false,
			} : undefined}
			navigation
			pagination={{ clickable: true }}
		>
			{
				props.content.map((el, idx) => {
					return <SwiperSlide key={'slide-elem-' + idx}>{el}</SwiperSlide>;
				})
			}
		</Swiper>
	);

};

export default SlideshowComponent;