import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { changePage } from '../../utils/routes';
import LoginModalComponent from '../login-modal/loginModal';
import config from '../../config.json';

import './navbar.css';

const NavBar = (props) => {

    const [activeIndexItem, setActiveIndexItem] = useState(-1);
    const [activeIndexItemMobile, setActiveIndexItemMobile] = useState(-1);
    const [isOpenSearch, setIsOpenSearch] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [activeDropDownMobileIndexItem, setActiveDropDownMobileIndexItem] = useState(-1);
    const userObj = JSON.parse(localStorage.getItem('loginObject'));

    useEffect(() => {
        setIsMobile(!props.context.isDesktop);
    }, []);

    useEffect(() => {
        setIsMobile(!props.context.isDesktop);
        if (props.context.isDesktop) {
            setOpenMobileMenu(false)
        }
    }, [props.context.isDesktop]);

    const parseMenuResponse = () => {
        const elements = [];
        props.menuResponse.forEach((menuItem, index) => {
            if (index > 0) {
                if (menuItem.havesubmenu && menuItem.havesubmenu !== '0') {// ho una voce di tipo dropdown
                    elements.push(
                        <div key={`key-navbar-${index}`} className="static dropdown">
                            <button className="dropbtn">
                                <span className="dropdownItem">
                                    <div className="containerDropDown">
                                        {menuItem.title} <i className="fas fa-chevron-down"></i>
                                    </div>
                                </span>
                            </button>
                            <div className="dropdown-content">
                                <div className="containerDropDownContentItem shadow">
                                    {parseSubMenuItem(menuItem.submenu, menuItem.type, index)}
                                </div>
                            </div>
                        </div>)
                } else { //ho una voce semplice
                    elements.push(<a key={`key-navbar-${index}`} onClick={() => {
                        setActiveIndexItem(index);
                        if (menuItem.type === 'static') {
                            changePage(props, '/' + menuItem.slug, undefined, menuItem.title, menuItem.description);
                        } else {
                            changePage(props, '/dynamic', { body: menuItem.body }, menuItem.title, menuItem.description);
                        }

                    }} className={index === activeIndexItem ? 'active static' : 'static'}>{menuItem.title}</a>);
                }
            }

        });
        const currIndex = elements.length + 1;
        elements.push(<a key="key-navbar-contact" onClick={() => {
            setActiveIndexItem(currIndex);
            changePage(props, '/contact', undefined, 'contact');
        }} className={currIndex === activeIndexItem ? 'active static' : 'static'}>Contact</a>);
        elements.push(<LoginModalComponent key="key-navbar-login" type="desktop"><i className="far fa-user"></i> Login</LoginModalComponent>)
        return (
            <>
                {elements}
            </>
        )
    }

    const parseMenuResponseMobile = () => {
        const elements = [];
        props.menuResponse.forEach((menuItem, index) => {
            if (index > 0) {
                if (menuItem.havesubmenu && menuItem.havesubmenu !== "0") {// ho una voce di tipo dropdown

                    elements.push(
                        <div key={`key-nav-mobile-${index}`}>
                            <button onClick={() => {
                                setActiveIndexItemMobile(-1);
                                if (activeDropDownMobileIndexItem !== index) {
                                    setActiveDropDownMobileIndexItem(index);
                                } else {
                                    setActiveDropDownMobileIndexItem(-1);
                                }
                            }} className={index === activeDropDownMobileIndexItem ? "active dropdown-btn" : "dropdown-btn"} >{menuItem.title}
                                <i className={index === activeDropDownMobileIndexItem ? "fa fa-caret-up" : "fa fa-caret-down"}></i>
                            </button>
                            <div className="dropdown-container">
                                {
                                    parseSubMenuItemMobile(menuItem.submenu, menuItem.type, index)
                                }
                            </div>
                        </div>
                    )
                } else { //ho una voce semplice
                    elements.push(<a key={`key-nav-mobile-${index}`} onClick={() => {
                        setActiveDropDownMobileIndexItem(-1);
                        setActiveIndexItemMobile(index);
                        openMenu(true);
                        if (menuItem.type === 'static') {
                            changePage(props, '/' + menuItem.slug, undefined, menuItem.title, menuItem.description);
                        } else {
                            changePage(props, '/dynamic', { body: menuItem.body }, menuItem.title, menuItem.description);
                        }
                    }} className={activeIndexItemMobile === index ? 'active' : ''}>{menuItem.title}</a>);
                }
            }
        });
        const currIndex = elements.length + 1;
        elements.push(<a key="key-nav-mobile-contact" onClick={() => {
            setActiveDropDownMobileIndexItem(-1);
            setActiveIndexItem(currIndex);
            openMenu(true);
            changePage(props, '/contact', undefined, 'contact');
        }} className={currIndex === activeIndexItem ? 'active' : ''}>Contact</a>);

        elements.push(<LoginModalComponent key="key-nav-mobile-login" type="tablet mobile"><i className="far fa-user"></i> Login</LoginModalComponent>)
        return (
            <>
                {
                    userObj && userObj.username ? (
                        <div className="sideNameUser">
                            <span>user: </span><b>{userObj.username}</b>
                        </div>
                    ) : null
                }
                {elements}
            </>
        )
    }


    const parseSubMenuItemMobile = (subMenu, parentType, index) => {
        const arraySubMenu = [];
        if (typeof subMenu === 'object') {
            subMenu.forEach((subMenuItem, subindex) => {
                if (subMenuItem.havesubmenu && subMenuItem.havesubmenu !== "0") {
                    // possono esistere voci di 3 livello ? no
                } else {
                    arraySubMenu.push(
                        <a key={`key-nav-mobile-${index}-sub-${subindex}`} onClick={() => {
                            openMenu(true);
                            if (parentType.type === 'static') {
                                changePage(props, '/' + subMenuItem.slug, undefined, subMenuItem.title, subMenuItem.description);
                            } else {
                                changePage(props, '/dynamic', { body: subMenuItem.body }, subMenuItem.title, subMenuItem.description);
                            }
                        }}>{subMenuItem.title}</a>
                    )
                }
            });
        }
        return arraySubMenu;
    }

    const parseSubMenuItem = (subMenu, parentType, index) => {
        const arraySubMenu = [];
        if (typeof subMenu === 'object') {
            subMenu.forEach((subMenuItem, subindex) => {
                if (subMenuItem.havesubmenu && subMenuItem.havesubmenu !== "0") {
                    // possono esistere voci di 3 livello ? no
                } else {
                    arraySubMenu.push(
                        <a key={`key-nav-${index}-sub-${subindex}`} onClick={() => {
                            if (parentType.type === 'static') {
                                changePage(props, '/' + subMenuItem.slug, undefined, subMenuItem.title, subMenuItem.description);
                            } else {
                                changePage(props, '/dynamic', { body: subMenuItem.body }, subMenuItem.title, subMenuItem.description);
                            }
                        }} className="dropdown-contentItem">
                            <div className="dropdown-contentItemChild">
                                <span className="suggestionText">{subMenuItem.title}</span>
                            </div>
                        </a>)
                }
            });
        }
        return arraySubMenu;
    }

    const openSearch = () => {
        setIsOpenSearch(!isOpenSearch);
    }

    const openMenu = (forceClose = false) => {
        if (forceClose) {
            if (document.querySelector('.stickyContainer .btnclose-container')) {
                document.querySelector('.stickyContainer .btnclose-container').classList.remove('change');
            }
            setOpenMobileMenu(false);
        } else {
            const val = document.querySelector('.stickyContainer .btnclose-container').classList.toggle('change');
            setOpenMobileMenu(val);
        }
    };

    const changeSearchValue = (event) => {
        setSearchValue(event.target.value);
    };

    const onSubmitSearch = () => {
        if (searchValue !== undefined && searchValue !== '') {
            if (props.location.pathname !== '/search') {
                changePage(props, '/search', { body: { searchValue: searchValue } }, '', '');
            } else { // aggiorno solo la parola che sto cercando
                props.history.replace((config.root ? '' : config.home) + '/search', { body: { searchValue: searchValue } });
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setTimeout(() => {
                onSubmitSearch();
            }, 400);
        }
    };

    return (
        <>
            <div style={{ right: openMobileMenu ? 0 : '-303px' }} className="sidepanel shadow">
                {
                    parseMenuResponseMobile()
                }
            </div>
            {
                userObj && userObj.username ? (
                    <div className="nameUser">
                        <span>user: </span><b>{userObj.username}</b>
                    </div>
                ) : null
            }
            <div className="stickyContainer shadow">
                <div className="w3-row rowContainer">
                    <div className="logoContainer w3-col responsive-m-l">
                        <img onClick={() => {
                            setActiveIndexItem(-1);
                            setActiveIndexItemMobile(-1);
                            openMenu(true);
                            changePage(props, '/', undefined, props.menuResponse[0].title, props.menuResponse[0].description);
                        }} src={!isMobile ? props.context.config['base-img-path'] + props.context.config['ks-logo-long'] : props.context.config['base-img-path'] + props.context.config['ks-logo']} alt="logo" />
                    </div>
                    <div className="topnav w3-col" style={{ width: (isOpenSearch || isMobile) ? 0 : '' }} id="myTopnav">
                        {
                            parseMenuResponse()
                        }
                    </div>
                    <div className={(isOpenSearch || isMobile) ? "w3-col containerEndSearchOpen" : "w3-col containerEnd"}>
                        <div className="formSearch" style={{ display: isOpenSearch ? 'flex' : 'none', width: isOpenSearch ? '90%' : 0 }}>
                            <input type="text" className="searchDesktop" placeholder="Search" onChange={changeSearchValue} onKeyDown={handleKeyDown} />
                            <div className="containerSearchButton">
                                <button onClick={onSubmitSearch}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <i style={{ marginLeft: isOpenSearch ? '10px' : 0, marginRight: isMobile ? '15px' : 0 }} className={isOpenSearch ? "fas fa-times" : "fas fa-search"} onClick={openSearch}></i>
                        {
                            isMobile ?
                                <div className="btnclose-container" onClick={() => { openMenu() }}>
                                    <div className="bar1"></div>
                                    <div className="bar2"></div>
                                    <div className="bar3"></div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(NavBar);
