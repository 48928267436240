import axios from 'axios';
import { useState } from 'react';
import { useLocalStorage } from 'react-use-storage';
import StandardsForm from '../../components/standardsForm/standardsForm';
import StandardsList from '../../components/standardsList/standardsList';
import config from '../../utils/config.json';


const StandardsPage = (props) => {
    const [userData] = useLocalStorage('loginObject', {});
    const [standards, setStandards] = useState([]);

    return (
        <div className="margin-top-page">
            <StandardsForm searchButtonCallback={handleSearch} />
            <StandardsList standards = {standards}/>
        </div>
    );

    function handleSearch(e) {
        
        let parameters = e;

        let standardsReq = {

            headers: {
                "Ocean-Component": "standard",
                "Ocean-Method": "list",
                "Ocean-Result": "json",
                "Ocean-User": userData.username,
                "Ocean-Token": userData.token
            },

            params: {
                ...parameters
            }
        }
        const standardsList = axios.get(config.endpoints.config, standardsReq)

        standardsList.then(res => {
            setStandards(res.data);
        })
    }



};

export default StandardsPage;