import './sticky-social-bar.css';

const StickySocialBar = (props) => {

    return (
        <div className="icon-bar w3-right" style={{ top: '35vh' }}>
            <a href="https://www.facebook.com/knowingscience" target="_blank" rel="noreferrer" className="facebook">
                <i className="fab fa-facebook-f" />
            </a>
            <a href="https://twitter.com/knowing_science" target="_blank" rel="noreferrer" className="twitter">
                <i className="fab fa-twitter" />
            </a>
            <a href="https://www.linkedin.com/company/knowing-science" target="_blank" rel="noreferrer" className="linkedin">
                <i className="fab fa-linkedin-in" />
            </a>
        </div>
    )

};

export default StickySocialBar;
