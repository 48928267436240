const DynamicPage = (props) => {

	const createMarkup = () => {
		if (props.location && props.location.state && props.location.state.body) {
			return { __html: props.location.state.body };
		} else {
			return { __html: '' };
		}

	}

	return (
		<div className='component-std margin-top-page'>
			<div dangerouslySetInnerHTML={createMarkup()}></div>
		</div>
	);

};

export default DynamicPage;
