import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import { useLocalStorage } from 'react-use-storage';
import axios from 'axios';
import NavBarDesktop from './components/navbar/navbar'
import Spinner from './components/spinner/spinner';
import cssVars from 'css-vars-ponyfill';
import Routes from './utils/routes';
import Footer from './components/footer/footer';
import StickySocialBar from "./components/sticky-social-bar/sticky-social-bar";
import { get } from './utils/httpRequests';


const AppDesktop = (props) => {

	const logoImgPath = useRef();
	const menuResponse = useRef();
	const footerMenuResponse = useRef();
	const contactRef = useRef();
	const [loading, setIsLoading] = useState(true);
	const [loginObject, setLoginObject] = useLocalStorage('loginObject', {});

	const checkDeviceType = () => {
		if (window.matchMedia('(max-width: 992px)').matches && window.matchMedia('(min-width: 601px)').matches) { //tablet
			props.context.setIsTablet(true)
			props.context.setIsPhone(false);
			props.context.setIsDesktop(false);
		} else if (window.matchMedia('(max-width: 600px)').matches) { //phone
			props.context.setIsPhone(true);
			props.context.setIsTablet(false);
			props.context.setIsDesktop(false);
		} else if (window.matchMedia('(min-width: 993px)').matches) { //desktop pc
			props.context.setIsDesktop(true);
			props.context.setIsPhone(false);
			props.context.setIsTablet(false);
		}
	}

	useEffect(() => {

		checkDeviceType();
		window.addEventListener('resize', (e) => {
			checkDeviceType();
		});

		const requestFooterMenu = get('list', 'f-menu', {}, loginObject);
		const requestConfig = get('select', 'config', {}, loginObject);
		const requestMenu = get('list', 'menu', {time: (new Date().getTime())}, loginObject);
		const requestLabels = get('list', 'traduction', {}, loginObject);

		axios.all([requestConfig, requestLabels, requestMenu, requestFooterMenu]).then(axios.spread((...responses) => {
			const dataConfig = responses[0].data;
			if(!dataConfig.miscellaneous) {
				setLoginObject({});
				localStorage.removeItem('token');
				localStorage.removeItem('username');
			}
			props.context.setGlobalConfig(dataConfig)
			logoImgPath.current = dataConfig['base-img-path'] + dataConfig['ks-logo-long'];
			const colors = {};
			colors['--ks-color-practices'] = dataConfig['ks-color-practices'];
			colors['--ks-color-core-ideas'] = dataConfig['ks-color-core-ideas'];
			colors['--ks-color-concepts'] = dataConfig['ks-color-concepts'];
			cssVars({
				variables: colors
			});
			props.context.setGlobalLabels(responses[1].data)
			menuResponse.current = responses[2].data;
			props.context.setDescription(menuResponse.current[0].description);
			props.context.setTitle(menuResponse.current[0].title + ' | Knowing Science');
			footerMenuResponse.current = responses[3].data;
			setIsLoading(false);
		})).catch(errors => {
		})

	}, []);

	const handleScroll = () => {
		const contact = contactRef.current;
		window.scrollTo({
			top: contact.offsetTop,
			left: 0,
			behavior: "smooth"
		});
	};

	return (
		<>
			{
				loading ? <Spinner /> :
					<div style={{ minHeight: '100vh', position: 'relative' }}>
						<div className='w3-row' style={{ minHeight: '100px' }}>
							<NavBarDesktop
								logo={logoImgPath.current}
								menuResponse={menuResponse.current}
								handleScroll={handleScroll}
								context={props.context}
							/>
						</div>
						<Routes />
						<StickySocialBar />
						<Footer notDesktop={props.context.isPhone || props.context.isTablet} menuResponse={footerMenuResponse.current} context={props.context} stickyMenuResponse={menuResponse.current} />
					</div>
			}
		</>
	)
}

export default AppDesktop;