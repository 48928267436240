import axios from 'axios';
import configJson from './config.json';

export const post = (method, component, bodyRequest = {}, loginObject = {}) => {
	const qs = require('qs');
	const user = loginObject.username ? loginObject.username : undefined;
	const token = loginObject.token ? loginObject.token : undefined;
	const baseConfig = {
		'Ocean-Component': component,
		'Ocean-Method': method,
		'Ocean-Result': 'json',
		'Content-Type': 'application/x-www-form-urlencoded'
	};
	const config = {
		method: 'post',
		url: configJson.endpoints.config,
		headers:
			token ? {
				'Ocean-User': user,
				'Ocean-Token': token,
				...baseConfig
			} : baseConfig,
		data: qs.stringify(bodyRequest)
	};
	return axios(config);
};

export const get = (method, component, queryString = {}, loginObject = {}) => {
	const qs = require('qs');
	const user = loginObject.username ? loginObject.username : undefined;
	const token = loginObject.token ? loginObject.token : undefined;
	const baseConfig = {
		'Ocean-Component': component,
		'Ocean-Method': method,
		'Ocean-Result': 'json',
		'Content-Type': 'application/x-www-form-urlencoded'
	};
	const config = {
		method: 'get',
		url: configJson.endpoints.config + '?' + qs.stringify(queryString),
		headers:
			token ? {
				'Ocean-User': user,
				'Ocean-Token': token,
				...baseConfig
			} : baseConfig
	};
	return axios(config);
};