import { withRouter } from 'react-router-dom';
import config from '../../config.json';

import './jumbotron.css';

const Jumbotron = (props) => {

    return (
        <section className="jumbotron component-std w3-container w3-theme w3-padding">
            <div className="w3-center">
                <h1 className="w3-xxxlarge w3-animate-bottom">Ready to learn more?</h1>
                <h4>Fill out this form, and we’ll be in touch with you shortly</h4>
                <div className="w3-padding-hor-32">
                    <button type="button" className="w3-button w3-button-light" onClick={() => {
                        props.history.push({
                            pathname: (config.root ? '' : config.home) + '/contact'
                        });
                        window.scrollTo({ top: 0 });
                    }}>
                        <div className="anim"></div>
                        <span>Get in touch</span>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default withRouter(Jumbotron);