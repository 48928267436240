import ContactForm from '../../components/contactForm/contactForm';

const ContactPage = (props) => {

	return (
		<div className="margin-top-page">
            <ContactForm />
        </div>
	);

};

export default ContactPage;