// import axios from "axios";
// import { useEffect, useState } from "react";
// import { useLocalStorage } from "react-use-storage";
// import Spinner from "../../components/spinner/spinner";
// import AttachmentModalComponent from "../../components/attachmentModal/attachmentModal";
// import config from '../../utils/config.json';
import "./teacherResourcesPage.css"


const TeacherResourcesPage = (props) => {

    return (
        <div className="margin-top-page w3-container component-std">
            <main class="postit">
                <p class="postit__message">
                    To access the resources that were available at <a href="http://knowingscience.com/teacherresources">http://knowingscience.com/teacherresources</a> login into your Knowing Science account or contact us in case you need to reset your password or want to create an account
                </p>
            </main>
        </div>
    );


}

export default TeacherResourcesPage;