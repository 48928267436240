import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use-storage';
import { withRouter } from 'react-router-dom';
import Spinner from '../../components/spinner/spinner';
import LessonCardComponent from '../../components/lessonCard/lessonCard';
import { get } from '../../utils/httpRequests';
import config from '../../config.json';
import Select from 'react-select';
import optionsGrade from '../../utils/grade.json';
import optionsDiscipline from '../../utils/discipline.json';

import './lessonsPage.css';

const LessonsPage = (props) => {
    const [loading, setIsLoading] = useState(true);
    const [result, setResult] = useState(true);
    const [userData, setLoginObject] = useLocalStorage('loginObject', {});
    const [classGrade, setClassGrade] = useState('');
    const [classDiscipline, setClassDiscipline] = useState('');

    useEffect(() => {
        get('list', 'lesson', {}, userData).then(response => {
            if (response.headers['ocean-renew'] !== undefined) {
                setLoginObject({
                    token: response.headers['ocean-renew'],
                    username: userData.username
                });
                setResult(response.data);
                setIsLoading(false);
            } else {
                setLoginObject({});
                props.history.push({ pathname: (config.root ? '' : config.home) + '/' });
                window.location.reload();
            }
        })
            .catch(e => {
                setIsLoading(false);
                setResult({});
            });
    }, []);

    const changeGrade = (val) => {
        setClassGrade(val.value === 'ALL' ? '' : ' grade_' + val.value.toLowerCase().replace(/\s/g, ''));
    };

    const changeDiscipline = (val) => {
        setClassDiscipline(val.value === 'ALL' ? '' : ' discipline_' + val.value);
    };

    return (
        <div className={'component-std lessonsPage' + classGrade + classDiscipline} style={{ minHeight: '100vh' }}>
            {
                loading ?
                    <Spinner />
                    :
                    <>
                        {
                            result.length > 0 ?
                                <div className='searchResult'>
                                    <h2 className='title'>Lesson</h2>
                                    <h5>Filters</h5>
                                    <div className="filters-area">
                                        <div>
                                            <Select
                                                onChange={changeGrade}
                                                className="custom-select"
                                                placeholder="Select Grade Filter..."
                                                options={[
                                                    { value: 'ALL', label: 'ALL' },
                                                    ...optionsGrade
                                                ]}
                                            />
                                        </div>
                                        <div>
                                            <Select
                                                onChange={changeDiscipline}
                                                className="custom-select"
                                                placeholder="Select Discipline Filter..."
                                                options={[
                                                    { value: 'ALL', label: 'ALL' },
                                                    ...optionsDiscipline
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="margin-top-page">
                                        <div className="row">
                                            {
                                                result.map((l, idx) => {
                                                    return (
                                                        <div
                                                            key={'key-lesson-page-' + idx}
                                                            className={'column grade-class-' + l.grade.toLowerCase().replace(/\s/g, '') + ' discipline-class-' + l.discipline}
                                                        >
                                                            <LessonCardComponent lesson={l} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                : <div>There are no results</div>
                        }
                    </>
            }
        </div>
    );
}
export default withRouter(LessonsPage);
